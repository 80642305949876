<template>
  <v-card flat class="px-5 pb-5">
    <v-card-title class="pb-8">Получение маршрут квитанции РЖД</v-card-title>
    <v-row justify="center" :class="selectedFile ? 'pl-4' : 'pl-0'">
      <v-col cols="12" md=12 class="pl-7 pr-7" style="display: flex;flex-direction: column; justify-content: center">
        <label for="images" :class="loading ? 'drop_container' : 'drop_container active'" @dragover="handleDragOver"
          @dragenter="handleDragEnter" @dragleave="handleDragLeave" @drop="handleDrop">
          <span class="file-name">{{ currentNameFile ? currentNameFile : 'файл не выбран' }}</span>
          <input :disabled="loading" type="file" id="images" required ref="fileInput" @change="handleFileChange"
            @click="openFilePicker('input')" />
        </label>
      </v-col>
    </v-row>
    <v-row class="mb-3 mt-1">
      <v-col cols="12" md="12" class="pr-7" style="display: flex; justify-content: flex-end">
        <v-btn @click="openFilePicker('btn')" :disabled="loading" color="orange" class="btn-todetermine"
          style=" width: fit-content;">
          <!--                  @click="uploadImage">-->
          Загрузить маршрут-квитанцию
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" class="pl-4">
      <v-col>
        <v-text-field outlined label="Номер билета" v-model="numberTicket" style="height: 3.5rem"> </v-text-field>
      </v-col>
      <v-col>
        <v-text-field outlined label="Стоимость тарифа" v-model="totalPrice" style="height: 3.5rem"
          :rules="[(val) => val > 0 || 'Введите число, например 10.50']"> </v-text-field>
      </v-col>
      <v-col class="col-3">
        <v-text-field outlined label="Сбор" v-model="costDP" style="height: 3.5rem"
          :rules="[(val) => val > 0 || 'Введите число, например 10.50']"> </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="12" md="12" style="display: flex; justify-content: flex-end">
        <v-btn @click="downloadReceipt" :loading="loading" color="orange" :disabled="isDisable"
          style="width: fit-content; height: 2.5rem">
          Получить
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from "axios";
import { downloadFile } from '@/mixins/utils.js';
export default {
  data() {
    return {
      isDragActive: false,
      loading: false,
      selectedFile: false,
      previewImage: "",
      currentNameFile: "",
      checkChange: false,
      numberTicket: "",
      totalPrice: "",
      costDP: "",
    };
  },
  methods: {
    validateTicketNumber: (val) => {
      return /[a-zA-Z0-9]/.test(val)
    },

    handleDragOver(event) {
      event.preventDefault();
    },
    handleDragEnter() {
      this.isDragActive = true;
    },
    handleDragLeave() {
      this.isDragActive = false;
    },
    handleDrop(event) {
      event.preventDefault();
      this.isDragActive = false;
      const droppedFile = event.dataTransfer.files[0];
      this.setSelectedFile(droppedFile);
      this.$refs.fileInput.files = event.dataTransfer.files;
      this.checkInputImage();
    },
    setSelectedFile(file) {
      this.selectedFile = file;
      this.currentNameFile = file.name;
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    checkInputImage() {
      if (this.selectedFile) {
        let reader = new FileReader();
        reader.onload = (event) => {
          this.previewImage = event.target.result;
        };
        this.currentNameFile = this.selectedFile.name;
        reader.readAsDataURL(this.selectedFile);
      } else {
        this.previewImage = "";
      }
    },
    openFilePicker(arg) {
      const fileInput = document.getElementById("images");

      fileInput.onchange = (event) => {
        this.selectedFile = event.target.files[0];
        console.log(this.currentNameFile)
        this.currentNameFile = this.selectedFile.name; // Обновляем название файла
        this.checkInputImage();
      };
      if (arg === 'input') {
        const fileInput = document.getElementById("images");

        fileInput.onchange = (event) => {
          this.selectedFile = event.target.files[0];
          this.checkInputImage();
        };
      } else if (arg === 'btn') {
        const fileInput = document.getElementById("images");

        fileInput.onchange = (event) => {
          this.selectedFile = event.target.files[0];
          this.checkInputImage();
        };
        fileInput.click();
      }
    },
    async downloadReceipt() {
      this.loading = true;
      try {
        this.currentNameFile = this.selectedFile.name;
        var formdata = new FormData();
        formdata.append("requestTrainDto.Receipt", this.selectedFile, "[PROXY]");
        const trainOrderDto = {
          ticketNumber: this.numberTicket,
          ticketCost: this.totalPrice,
          markup: this.costDP,
        };
        let trainOrderDtoNew = JSON.stringify(trainOrderDto);
        formdata.append("requestTrainDto.TrainOrderDto", trainOrderDtoNew);
        let resp = await axios.post(this.$enums.Endpoints.Other.ExternalReceiptRailway, formdata, {
          responseType: "blob",
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        downloadFile(resp.data, { name: `${this.numberTicket}_receipt.pdf` })

      } catch (e) {
        throw e;
      } finally {
        this.loading = false;
      }
    },
    watch: {
      selectedFile() {
        this.checkInputImage();
      },
      result() {
        if (this.currentNameFile !== this.selectedFile.name) {
          this.checkChange = false;
        }
      }
    },
  },
  computed: {
    isDisable() {
      return (!this.selectedFile || this.numberTicket.length === 0 || this.totalPrice.length === 0 || this.costDP.length === 0);
    },
  },
}
</script>

<style scoped>
.drop_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 15vh;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  border: 2px dashed orange;
  color: orange;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
  position: relative;
  padding: 10px;
}

.active:hover {
  background: #41372e;
  border-color: orange;
}

.drop_container:hover .drop_title {
  color: #ffffff;
}

.drop_title {
  color: orange;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}

.drop_container.drag_active {
  background: #eee;
  border-color: #111;
}

input[type=file] {
  opacity: 0;
}

input[type=file]::file-selector-button {
  display: none;
  background: orange;
  cursor: pointer;
  border-radius: 10px;
  border: none;
  opacity: 0;
}

input[type=file]::content {
  opacity: 0;
}

input[type=file]::file-selector-button:hover {
  background: orange;
}

.file-name {
  width: 95%;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  z-index: 1;
  cursor: pointer;
}

.image-view {
  height: 10rem;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-view img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  flex-shrink: 0;
}

.image-notview {
  height: 0;
  width: 0;
}

.btn-todetermine:hover {
  background: orange;
  transform: translateY(0) scale(1.005);
}
</style>