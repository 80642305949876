<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="3">
        <v-tabs vertical v-model="tab">
          <v-tab v-if="isAllowQR">Генерация QR</v-tab>
          <!-- <v-tab>Квитанция об оплате</v-tab> -->
          <v-tab v-if="currentAccount">Авиа МК</v-tab>
          <v-tab v-if="currentAccount">ЖД МК</v-tab>
          <v-tab v-if="currentAccount">Объединение МК</v-tab>
          <!-- <v-tab v-if="currentAccount">Исправление liner МК</v-tab> -->
          <v-tab v-if="currentAccount">ВВСС</v-tab>
          <v-tab v-if="currentAccount">Гостиница Атланта</v-tab>
          <v-tab v-if="currentAccount">Отправить письмо</v-tab>
          <v-tab v-if="isFeedbackAllowed">Отзывы</v-tab>
          <v-tab v-if="isAllowReport">Получить отчёт</v-tab>
          <v-tab v-if="currentAccount">Скоро...</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12" md="9">
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="isAllowQR">
            <qr class="window"></qr>
          </v-tab-item>
          <!-- <v-tab-item>
            <payment-receipt class="window"></payment-receipt>
          </v-tab-item> -->
          <v-tab-item v-if="currentAccount">
            <geting-route-receipt />
          </v-tab-item>
          <v-tab-item v-if="currentAccount">
            <geting-railway-r-r />
          </v-tab-item>
          <v-tab-item v-if="currentAccount">
            <union-pdf />
          </v-tab-item>

          <!-- <v-tab-item v-if="currentAccount">
            <liner-pdf-editor />
          </v-tab-item> -->
          <v-tab-item v-if="currentAccount">
            <Wwss />
          </v-tab-item>
          <v-tab-item v-if="currentAccount">
            <hotel-blank />
          </v-tab-item>
          <v-tab-item v-if="currentAccount">
            <sending-mail />
          </v-tab-item>
          <v-tab-item v-if="isFeedbackAllowed">
            <feed-back />
          </v-tab-item>
          <v-tab-item v-if="isAllowReport">
            <report />
          </v-tab-item>
          <v-tab-item v-if="currentAccount">
            <v-card flat>
              <v-card-text>
                <v-img :src="require('@/assets/img/in-development.png')" max-height="300" contain class="mt-2"></v-img>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SendingMail from "@/components/SupportPage/SendMail/SendingMail.vue";
import Qr from "@/components/SupportPage/QR/Qr.vue";
import GetingRouteReceipt from "@/components/SupportPage/GetRouteReceipt/GetingRouteReceipt.vue";
import PaymentReceipt from "@/components/SupportPage/PaymentReceipt/PaymentReceipt.vue";
import GetingRailwayRR from "@/components/SupportPage/GetRailwayRR/GetingRailwayRR.vue";
import Wwss from "@/components/SupportPage/Blank/WWSS/Wwss.vue";
import HotelBlank from "@/components/SupportPage/Blank/Hotel/HotelBlank.vue";
import FeedBack from "@/components/SupportPage/FeedBack/FeedBack.vue";
import UnionPdf from "@/components/SupportPage/UnionPdf/UnionPdf.vue";
import LinerPdfEditor from "@/components/SupportPage/Blank/Liner/LinerPdfEditor.vue";
import Report from "@/components/SupportPage/ReportPage/Report.vue";

export default {
  components: {
    LinerPdfEditor,
    Report,
    UnionPdf,
    FeedBack,
    Wwss,
    GetingRouteReceipt,
    PaymentReceipt,
    SendingMail,
    Qr,
    GetingRailwayRR,
    HotelBlank,
  },
  data() {
    return {
      tab: null,
      disabledAccounts: [15, 25, 37, 38, 39, 40, 41, 42],
    };
  },
  methods: {
    isS7AdminEmail(email) {
      const emails = ["admin.s7@bpo.travel", "s.mogutova@s7.ru", "e.balatskaya@s7.ru", "t.pidzhakova@s7.ru"]
      return emails.includes(email)
    },
  },
  computed: {
    isFeedbackAllowed() {
      return (
        this.$store.getters.user.role === this.$enums.Roles.Admin ||
        this.$store.getters.user.role === this.$enums.Roles.Technologist
      );
    },
    isAllowQR() {
      return this.$store.getters.user.accountSetting.canGenerateQR;
    },
    isAllowReport() {
      return this.$store.getters.user.accountSetting.canGetReport;
    },
    currentAccount() {
      return !this.isS7AdminEmail(this.$store.getters.user.email)
    }
  },
};
</script>

<style></style>
