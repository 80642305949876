<template>
  <v-card flat class="px-5 pb-5">
    <v-card-title>Отправка Письма</v-card-title>
    <v-row align-content="center" justify="center">
      <v-col cols="12" md="4" class="text-color">
        <v-text-field outlined label="Кому" v-model="email"> </v-text-field>
        <v-file-input
          v-model="files"
          label="Приложения"
          multiple
          show-size
          counter
          prepend-icon="mdi-paperclip"
          class="file-input"
        >
          <template v-slot:selection="{ text }">
            <v-chip small label>
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
      </v-col>
      <v-col cols="12" md="8">
        <v-text-field outlined label="Тема" v-model="subject"> </v-text-field>
        <v-textarea outlined label="Текст" v-model="text"> </v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3" offset-md="9" class="text-end">
        <v-btn :loading="isSending" @click="send" color="orange">
          Отправить
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      text: "",
      subject: "",
      files: [],
      strings: [],
      isSending: false,
    };
  },
  methods: {
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async tobase64Handler(files) {
      const filePathsPromises = [];
      const names = [];
      files.forEach((file) => {
        filePathsPromises.push(this.toBase64(file));
        names.push(file.name);
      });
      const filePaths = await Promise.all(filePathsPromises);
      const mappedFiles = filePaths.map((base64File) => ({
        fileBase64: base64File.substring(base64File.indexOf(",") + 1),
        name: "",
      }));
      for (var i = 0; i < mappedFiles.length; i++) {
        mappedFiles[i].name = names[i];
      }
      console.log(mappedFiles);
      return mappedFiles;
    },
    async send() {
      this.isSending = true;
      var files = await this.tobase64Handler(this.files);
      var req = {
        emailFrom: "ticket@bpo.travel",
        emailTo: this.email,
        message: this.text,
        subject: this.subject,
        files: files,
      };
      try {
        console.log("Start send mail");
        let resp = await this.$axios.post(this.$enums.Endpoints.Other.SendMail, req);
        console.log("End send email");
      } catch (e) {
        console.log("SendEmail - Error");
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        };
        return;
      } finally {
        this.isSending = false;
      }
    },
  },
};
</script>

<style>
.file-input:hover {
  cursor: pointer !important;
}
</style>
