<template>
  <v-container>
    <v-col>
      <v-data-table
          :headers="headers"
          :items="smsData"
          :items-per-page="options.itemsPerPage"
          :server-items-length="totalRows"
          :options.sync="options"
          @update:options="updateOptions"
          :loading="feedBackLoading"
          class="elevation-1 "
      ></v-data-table>
    </v-col>
  </v-container>
</template>

<script>
export default {
  props: {
    smsData: {
      type: Array,
      required: true,
      default: () => ({})
    },
    totalRows: {
      type: Number,
      required: true,
    },
    feedBackLoading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
      },
      headers: [
        {text: 'Дата формирования мк', value: 'sentAt', class: 'table-header-date'},
        {text: 'Дата отзыва', value: 'receivedAt', class: 'table-header-date'},
        {text: 'Касса', value: 'createdBy.name', class: 'table-header-name'},

        { text: 'Телефон', value: 'phone', class: 'table-header-phone' },
        { text: 'ФИО', value: 'fullName', class: 'table-header-name', cellClass: 'table-cell-name' },
        { text: 'Оценка', value: 'rating', class: 'table-header-rating' },
        {text: 'Отзыв', value: 'text', cellClass: 'table-cell-text'},
      ],
    }
  },
  methods: {
    updateOptions(newOptions) {
      this.feedBackLoading = true
      this.options = newOptions;
      this.$emit('update:options', newOptions);
      this.feedBackLoading = false
    },
  },
}
</script>

<style>
.table-header-date {
  min-width: 190px;
  max-width: 190px;
}

.table-header-name {
  min-width: 90px;
  max-width: 250px;
}
.table-header-phone {
  min-width: 100px;
  max-width: 100px;
}
.table-header-rating {
  min-width: 120px;
  padding: 2px;
}

.table-cell-text {
  max-width: 600px;
}
.table-cell-name {
  white-space: nowrap !important;
}


</style>
