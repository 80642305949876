<template>
    <v-card flat class="px-5 pb-5">
        <v-card-title class="pb-8">Объединение и отправка МК</v-card-title>
        <file-input v-model="files" multiple accept=".pdf" id="union" />
        <v-row justify="space-between" class="mt-4">
            <v-col cols="12" md="4" align-self="center">
                <div v-if="!files.length" class="text-h5"><v-icon class="mr-3 text-h4">mdi-information</v-icon> Загрузите
                    PDF-документы
                </div>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field outlined label="Почта" v-model="email" style="height: 3.5rem" append-icon="mdi-email-plus"
                    @click:append="email = $store.state.user.email"> </v-text-field>
            </v-col>
            <v-col cols="12" md="4" style="display: flex; justify-content: flex-end">
                <v-btn @click="send" :loading="loading" color="orange" :disabled="isDisable"
                    style="width: 100%; height: 3.3rem">
                    Получить
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>
  
<script>
import axios from "axios";
import FileInput from "@/components/UI/FileInput.vue";
import { downloadFile } from '@/mixins/utils.js';
export default {
    name: 'UnionPdf',
    components: { FileInput },
    data() {
        return {
            files: [],
            email: "",
            loading: false,
        };
    },
    methods: {
        async send() {
            this.loading = true;
            console.log("asdasd")
            try {
                var command = new FormData();
                command.append("Email", this.email ?? "");
                this.files.forEach(e => command.append("Files", e, "[PROXY]"))

                let resp = await axios.post(this.$enums.Endpoints.Other.Receipt.UnionAndSend, command, {
                    responseType: "blob",
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })

                downloadFile(resp.data, { name: `${this.numberTicket}_receipt.pdf` })

            } catch (e) {
                throw e;
            } finally {
                this.loading = false;
            }
        }
    },
    computed: {
        isDisable() {
            return !(this.files ?? []).length;
        },
    },
}
</script>
  
<style></style>