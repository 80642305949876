<template>
    <v-dialog v-model="value" persistent width="500">
        <v-card >
          <v-card-title class="text-h5 text-center green lighten-2 white--text error-title pl-10">
            Ввод номера телефона пассажира
          </v-card-title>
  
          <v-card-text class="pt-10">
            <v-col cols="12" class="text-center">
              <v-text-field outlined label="Номер телефона" v-model="phoneNumber"/>
            </v-col>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-btn color="primary" text @click="$emit('closeModal')">
                    Закрыть
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn color="primary" text @click="$emit('setPhoneNumber', phoneNumber)">
                    Подтвердить
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
   
    </template>
    
    <script>
  
    export default {
      name: "InputPhoneModal",
      props: {
        value: {
          type: Boolean,
          default: false,
        },
      },
      data() {
        return {
          phoneNumber: undefined,
        }
      },
  
    };
    </script>
    
    <style scoped>

    </style>
    